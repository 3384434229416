<template>
    <div class="relative top-0 bottom-0 left-0 right-0 z-20 bg-background">
        <div class="min-h-screen bg-background pt-3 mt-2 md:pt-3 mt-2 lg:pt-10">
            <nav class="bg-white border-b border-gray-100 fixed top-0 left-0 right-0 z-50">
                <div class="mx-auto px-4 sm:px-6 lg:px-8 bg-layout">
                    <div class="flex justify-between h-16">
                        <div class="flex items-center">
                            <div class="shrink-0 flex items-center">
                                <router-link :to="{ name: 'dashboard' }">
                                    <img src="../assets/botLogo.png" alt="" class="w-20 h-20 rounded-md" />
                                </router-link>
                                <h2 class='font-bold text-2xl text-sky-950'>LeadInsightBot</h2>

                            </div>
                        </div>

                        <div class="flex justify-between">
                            <div class="flex items-center">
                                <div class="hidden sm:flex sm:items-center">
                                    <div class="relative z-50">
                                        <button @click="showUserDropdown = !showUserDropdown" type="button"
                                            class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150">
                                            {{ username }}
                                            <i class="fas fa-caret-down"></i>
                                        </button>
                                        <div v-if="showUserDropdown"
                                            class="absolute right-0 mt-2 w-48  rounded-md shadow-lg">
                                            <form method="post" as="button" @submit.prevent="logout">
                                                <button type="submit"
                                                    class="block w-full text-left px-4 py-2 text-sm text-red-700 rounded-xl bg-gray-100 hover:bg-gray-200">Déconnexion</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </nav>


            <aside class="fixed top-16 left-0 z-50 h-screen transition-transform -translate-x-full sm:translate-x-0"
                aria-label="Sidebar">
                <div class="h-full w-60 px-3 py-4  bg-white">
                    <ul class="font-medium ml-5">
                        <li class="pt-3 mt-2">
                            <router-link :to="{ name: 'dashboard' }" class="flex gap-2 items-center "
                                :class="{ 'bg-blue-500 text-white rounded-lg p-1': $route.name === 'dashboard' }">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                                Tableau de bord
                            </router-link>
                        </li>

                        <li class="pt-3 mt-2">
                            <router-link :to="{ name: 'ParamLeads' }" class="flex gap-2 items-center "
                                :class="{ 'bg-blue-500 text-white rounded-lg p-1': $route.name === 'ParamLeads' }">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                </svg>
                                Leads Collectés
                            </router-link>
                        </li>
                        <li class="pt-3 mt-2">
                            <router-link :to="{ name: 'userAccess' }" class="flex gap-2 items-center"
                                :class="{ 'bg-blue-500 text-white rounded-lg p-1': $route.name === 'userAccess' }">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                </svg>



                                Utilisateurs
                            </router-link>
                        </li>

                    </ul>
                </div>


            </aside>

            <header class="bg-background shadow sticky top-16" v-if="$slots.header">
                <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 mt-16">
                    <slot name="header" />
                </div>
            </header>

            <main>
                <div class="sm:pl-[16rem] sm:pr-[2.5rem] pt-16 z-50 ">
                    <slot />
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import config from "@/main.js";


const showUserDropdown = ref(false);

// const showParametrageMenu = ref(false);


const username = ref(null);

username.value = localStorage.getItem("username");

const router = useRouter();


const logout = () => {
    axios.post(`${config.BACK_URL}/api/logout`, {}, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then((response) => {
        if (response.data.success == true) {
            //supprimer les informations de l'utilisateur connecté du local storage
            localStorage.removeItem('userId');
            localStorage.removeItem('username');
            localStorage.removeItem('token');

            //rediriger vers la page de connexion
            router.push({ name: 'connexion' });

        }
    })
}


document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

});
</script>