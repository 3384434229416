<template>
  <!-- Entête de l'interface de conversation -->
  <chatHeader />
  <main ref="pageContainer"
    class="m-0 mt-[-1rem] sm:w-[70vw] sm:mx-auto xl: w-full overflow-hidden md:w-[65vw] xl:w-[60vw] 2xl:w-[59vw] md:mx-auto lg:mx-auto ">
    <!-- Interface de discussion -->
    <div class="p-3 mb-[10rem] md:mr-[2%] lg:mr-[5%] 2xl:mr-[6%]">
      <div class="">
        <!-- partie pour la gestion du fil de discussion -->
        <section v-for="(message, index) in chatHistory" :key="index" class="flex">
          <!-- partie affichant les messages du bot -->
          <section v-if="message.role == 'bot'" class="">
            <!-- affiche les phrases d'introduction du bot -->
            <div v-show="message.welcomeMsg.length > 0" class="">
              <div class="flex flex-col gap-1 contenu" v-for="(value, index) in message.welcomeMsg" :key="index">
                <div class="flex items-center gap-6" >
                  <!-- <img class="del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre"> -->
                  <p class="bg-blue-500 w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p>
                  <p
                    class="chat-bubble w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem] mt-1">
                    {{ value }}</p>

                  </div>
                  <p class="flex justify-center items-center" v-if="value == 'A la recherche de la meilleure offre ? Vous êtes au bon endroit !!! 🤗. En plus d\'un'">
                      <img
                        class="w-72 lg:w-96 rounded-md"
                        src="../assets/mascottesleo/super_prix.png"
                        alt="Détective"
                      />
                    </p>
              </div>
            </div>
            <!-- affiche les affirmations et la question posées par le bot -->
            <div class="message mt-[0.25rem] m-0 flex items-center gap-6">
              <div class="flex flex-col gap-1">
                <div v-if="message.affirmation.length > 0" class="flex gap-6 ">
                  <p class="bg-blue-500 w-8 h-8 lg:w-10 lg:h-10 opacity-0"></p>
                  <div class="flex flex-col gap-1">
                    <div v-for="(value, index) in message.affirmation" :key="index">
                      <p class="chat-bubble w-[80vw] sm:w-[58vw]  p-2 text-[0.90rem] md:text-[1rem]  md:w-[54vw]   xl:w-[51vw] 2xl:[52vw]">
                        {{ value }} </p>
                        <p v-if="value == 'Casquette de détective activée 🧢 pour dénicher votre offre ! 🧐'"
                            class="flex justify-center items-center">
                            <img class="w-60 h-60 lg:w-72 lg:h-72 rounded-md" src="../assets/mascottesleo/leoremov.webp" alt="leo" />
                          </p>
                    </div>
                  </div>
                </div>
                <div class="flex gap-6">
                  <img class="w-8 h-8 lg:w-10 lg:h-10 object-cover m-0" src="../assets/leo.png" alt="mascotteTigre" :class="{'mt-3 md:mt-0' : message.question == 'Pourquoi changer de fournisseur ?' }">
                  <p class="chat-bubble w-[80vw] sm:w-[58vw]  p-2 text-[0.90rem] md:text-[1rem]  md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] "
                    :class="{ 'w-[78vw], ': message.question == 'Toujours engagé(e) ?' }">
                    {{ message.question }}</p>
                </div>
              </div>
            </div>
          </section>
          <!-- partie affichant les messages de l'utilisateur -->
          <section v-else class="message animation_chat message-right mt-2 mb-1 w-full">
            <div
              class="chat-bubble_answer text-white p-2 text-[0.90rem] md:text-[1rem] ">
              {{ message.content }}
            </div>
          </section>
        </section>
        <!-- partie pour l'ajout dynamique des questions et suggestions de réponse du bot -->
        <div class="">
          <!-- ***** Message d'ouverture de Léo (Début) **** -->
          <section v-show="beginMsg.length > 0" class="flex flex-col gap-1">
            <!--***** Premier message *****-->
            <section v-if="show1" class="flex flex-col gap-3 p-0">
              <div class="flex items-center gap-6">
                <img :class="{ 'opacity-0': show2 }" class="opacity-1 del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png" alt="mascotteTigre">
                <p
                  class="chat-bubble animation_chat w-[80vw] sm:w-[58vw] md:w-[60vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem] ">
                  Bonjour, moi c'est Léo, votre bot Internet !</p>
              </div>
              <Chat_loader v-if="!show2 && !show3 && !showBotAnswer" class="ml-16" />
            </section>
            <!--***** Deuxième message *****-->
            <section v-if="show2" class="flex flex-col gap-3">
              <div class="flex items-center gap-6">
                
                  <img :class="{ 'opacity-0': show3 }" class="opacity-1 animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                    src="../assets/leo.png" alt="mascotteTigre">
                  <p
                    class="chat-bubble animation_chat w-[80vw] sm:w-[58vw] md:w-[60vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem] ">
                    A la recherche de la meilleure offre ? Vous êtes au bon endroit !!! 🤗. En plus d'un
                  </p>
                
                </div>
                
              <Chat_loader v-if="!show3 && show2 && show1" class="ml-16" />
            </section>          

            <section v-if="show3" class="flex flex-col gap-3">
            <div>
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': showBotAnswer }"
                  class=" del opacity-0 w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
              </div>
              <p class="mt-[-2rem] flex justify-center items-center">
                <img class="w-72 lg:w-96  rounded-md" src="../assets/mascottesleo/super_prix.png" alt="Détective" />
              </p>
            </div>
            <Chat_loader v-if="show3 && show2 && show1 && !showBotAnswer" class="ml-16" />
          </section>
          </section>
          <!-- ***** Message d'ouverture de Léo (Fin) **** -->
          <!-- cette partie affichera les messages du bot -->
          <div class="">
            <div class="">
              <Chat_loader v-if="showAnswerLoader" class="ml-16" />
            </div>
            <!-- ***** Question et suggestion de réponses du bot (Début) **** -->
            <section v-if="showBotAnswer" class="">
              <div>
                <!-- affiche les affirmations du bot -->
                <section v-if="showBotAnswer" v-show="botAnswer.length > 0">
                  <section class="flex flex-col gap-1 mt-[0.25rem] ">
                    <section class="flex flex-col gap-3 ">
                      <div v-if="showAffirmation1" class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation2 }" class="del w-8 h-8 lg:w-10 lg:h-10 m-0"
                          src="../assets/leo.png" alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                          {{ showAffirmation }}</p>
                      </div>
                      <Chat_loader v-if="!showAffirmation2 && showAffirmation1" class="ml-16" />
                    </section>
                    <section class="flex flex-col gap-3 ">
                      <div v-if="showAffirmation2 && botAnswer.length > 1" class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                          Très bien, c'est noté. Alors continuons…
                        </p>
                      </div>
                      <Chat_loader v-if="showAffirmation1 && showAffirmation2 && !showQuestion" class="ml-16" />
                    </section>
                  </section>
                  <section v-if="showAffirmation3" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img :class="{ 'opacity-0': showQuestion }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png" alt="mascotteTigre">
                      <p
                        class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                        {{ showAffirmation }}</p>
                    </div>
                    <Chat_loader v-if="!showQuestion && showAffirmation3" class="ml-16" />
                  </section>
                  <section v-if="showAffirmation4" class="flex flex-col gap-3">
                    <div class="flex items-center gap-6">
                      <img :class="{ 'opacity-0': showQuestion }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                        src="../assets/leo.png" alt="mascotteTigre">
                      <p
                        class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                        {{ showAffirmation }}</p>
                    </div>
                    <Chat_loader v-if="!showQuestion && showAffirmation4" class="ml-16" />
                  </section>
                  <section class="flex flex-col gap-1">
                    <section v-if="showAffirmation5" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showAffirmation6 }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                          Merci pour toutes ces informations.</p>
                      </div>
                      <Chat_loader v-if="showAffirmation5 && !showAffirmation6 && !showAffirmation7 && !showQuestion"
                        class="ml-16" />
                    </section>
                    <section v-if="showAffirmation6" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">
                        <div>
                          <p class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">                     
                          Casquette de détective activée 🧢 pour dénicher votre offre ! 🧐</p>
                        </div>
                      </div>
                      <p class="flex justify-center items-center">
                        <img
                          class="w-72 h-72 lg:w-80 lg:h-80 rounded-md"
                          src="../assets/mascottesleo/leoremov.webp"
                          alt="Détective"
                        />
                      </p>
                      <Chat_loader v-if="showAffirmation5 && showAffirmation6  && !showQuestion"
                        class="ml-16" />
                    </section>
                    <!-- <section v-if="showAffirmation7" class="flex flex-col gap-3">
                      <div class="flex items-center gap-6">
                        <img :class="{ 'opacity-0': showQuestion }"
                          class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png"
                          alt="mascotteTigre">


                        <p
                          class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem] ">
                          Dernière ligne droite !</p>
                      </div>
                      <Chat_loader v-if="showAffirmation5 && showAffirmation6 && showAffirmation7 && !showQuestion"
                        class="ml-16" />
                    </section> -->
                  </section>
                </section>
                <div class="animation_chat  message-left flex items-center gap-6">
                  <img v-if="showQuestion" class="del animation_chat w-8 h-8 lg:w-10 lg:h-10 m-0"
                    src="../assets/leo.png" alt="mascotteTigre">
                  <div v-if="showQuestion"
                    class="chat_bubble_question w-[80vw] sm:w-[58vw]  md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] mt-[0.25rem] p-2 text-[0.90rem] md:text-[1rem] "
                    :class="{ 'mt-0': isCodePostalForm }">
                    <p class="">{{ firstQuestion }}</p>
                    <div class="">
                      <!-- Formulaire Code Postal -->
                      <div v-if="isCodePostalForm"
                        class="inline-block bg-white rounded-md p-4 m-1  2xl:w-[50vw] ] w-[260px]">
                        <div>
                          <p>(<span
                              class="text-black text-[0.90rem] md:text-[1rem] font-bold">*</span>)
                            Champ
                            obligatoire</p>
                        </div>
                        <div class="flex justify-center mt-3 border-2 w-full md:w-[40vw] rounded-lg pb-4">
                          <div class="mt-3 ">
                            <p><span
                                class="text-black text-[0.90rem] md:text-[1rem] font-bold">*</span>
                              Code Postal
                            </p>
                            <input v-model="codePostal"
                              class="2xl:h-[2rem] border-2 border-teal-400 w-full md:w-[30vw]  mt-2 pl-2"
                              placeholder="75001">
                            <div class="error-message mt-2" v-if="error">{{ error }}</div>
                          </div>
                        </div>
                      </div>
                      <!-- Formulaire découverte offre -->
                      <div v-else-if="isOfferForm"
                        class="inline-block bg-white  p-4 m-1 2xl:w-[48vw] md:w-[52vw] xl:w-[45vw] w-[70vw] sm:w-[55vw]">
                        <div>
                          <p>(<span
                              class="text-black text-[0.90rem] md:text-[1rem] font-bold">*</span>)
                            Champs
                            obligatoires</p>
                        </div>
                        <div class="flex flex-col mt-3 w-full">
                          <!-- Form1 -->
                          <div class="flex flex-wrap border-2 gap-2 rounded-lg p-3">
                            
                            <div class="mb-4 w-full ">
                              <label for="telephone"
                                class="block text-[0.90rem] md:text-[1rem] font-medium text-gray-600"><span
                                  class="text-black text-[0.90rem] md:text-[1rem] font-bold">*</span>
                                Téléphone
                                :</label>
                              <input id="telephone"
                                class="mt-1 p-1 border-2 border-solid rounded-md  w-full text-[0.90rem] md:text-[1rem]"
                                placeholder="veuillez entrer votre numéro" type="tel" v-model="numero_client">
                              <p v-if="numeroError"
                                class="text-red-500 text-[0.90rem] md:text-[1rem]">
                                {{ numeroError
                                }}</p>
                            </div>
                          </div>
                          <div class="flex justify-center">
                            <button v-if="showSuivantButton" @click="nextStep()"
                              class="bg-cyan-600 text-white m-1 mb-1 p-1 rounded-md">Suivant</button>
                          </div>
                          <!-- Form2 -->
                          <div v-if="currentStep" class="mt-[1.5rem] text-justify flex flex-col mb-1">
                            <p class="mb-1 font-bold">
                              Vous y êtes presque, plus que quelques clics pour terminer !
                            </p>
                            <div class="mb-[0.75rem]">
                              <input id="firstAgreement" type="checkbox" class="mr-2" v-model="firstAgreement">
                              <label for="firstAgreement"
                                class="text-[0.90rem] md:text-[1rem]">
                                En cliquant sur <span class="underline">« Je découvre mon offre »</span> vous
                                donnez
                                votre accord pour être contacté(e) par e-mail, téléphone, sms, whatsapp par VippData
                                ou
                                l'un
                                de ses partenaires pour une présentation personnalisée, conformément à nos <span
                                  class="underline"><a target="_blank" href="/conditionsgenerales"
                                    class="underline">CGU</a></span>.
                              </label>
                            </div>
                            <div
                              class="text-[0.90rem] md:text-[1rem] mb-[0.75rem]">
                              Vos données sont recueillies pour permettre à VippData et à ses partenaires de vous
                              contacter pour vous présenter une proposition adaptée à vos besoins. Pour en savoir
                              plus
                              sur
                              la gestion de vos données personnelles et l'exercice de vos droits, rendez-vous sur la
                              page

                              <a class=" underline break-words" target="_blank"
                                href="https://meilleursforfaitsinternet.fr/politiqueConfidentialite">
                                https://meilleursforfaitsinternet.fr/politiqueConfidentialite
                              </a>
                            </div>
                            <div
                              class="text-[0.90rem] md:text-[1rem] font-bold text-center">
                              Ce contact est
                              entièrement gratuit et sans aucun
                              engagement.</div>
                          </div>
                          <p v-if="showCGUmsg"
                            class="text-red-600 text-[0.90rem] md:text-[1rem]">
                            Merci de prendre
                            connaissance de nos
                            <span class="underline">CGU</span> avant d'accéder à votre offre.
                          </p>
                        </div>
                      </div>
                      <!-- Affiche les réponses basiques -->

                      <section class="flex flex-wrap justify-center items-center  gap-6 lg:gap-10 mt-4">
                        <div class="flex flex-col justify-center md:items-center" :disabled="chooseAnswer"
                          v-for="(value, index) in suggested_answers" :key="index">
                          <!-- <img v-if="value.answerImg != null" class="mx-auto object-cover w-[60px] h-[60px]" -->
                            <!-- :src="value.answerImg" alt="mascotteTigre"> -->

                          <button v-if="showAnswerButton && value.answer === 'Je découvre mon offre'" 
                          @click="handleButtonClick(value.answer)" :disabled="isDisabled" :id="firstAgreement ? 'decouvreOffre' : ''"
                            :class="{ 'hover:bg-red-500': !firstAgreement, 'bg-cyan-600': true }" 
                            class="transition-all duration-500 ease-out font-semibold text-white p-2 w-[120px] md:w-[130px] lg:w-[200px] rounded-md text-[0.70rem] lg:text-[0.80rem]"
                            @mouseover="handleInteraction" @mouseleave="handleMouseLeave"
                            @touchstart="handleInteraction" @touchend="handleMouseLeave">

                            <span>{{ displayText }}</span>
                          </button>


                          <button v-if="showAnswerButton && value.answer !== 'Je découvre mon offre'"
                            @click="getAnswerChosen(value.answer)"
                            class="transition-all duration-500 ease-in font-semibold text-white p-1 w-[128px] md:w-[138px] lg:w-[208px] lg:hover:bg-cyan-600 rounded-md">
                          <img v-if="value.answerImg != null" class="mx-auto object-cover w-[60px]" :src="value.answerImg" alt="mascotteTigre"/>
                            <span class="bg-cyan-600 block rounded-md text-[0.90rem] p-2 lg:text-[1rem] w-[120px] md:w-[130px] lg:w-[200px]">
                            {{ value.answer }}
                          </span>
                          </button>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ***** Question et suggestion de réponses du bot (Fin) **** -->
          </div>
        </div>
        <div class="flex flex-col gap-1 mt-[0.25rem]" v-if="messageFinal">
          <section v-if="showAffirmation7" class="flex flex-col gap-3">
            <!-- <div class="flex items-center gap-6">
              <img :class="{ 'opacity-0': showAffirmationTadaam }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png" alt="mascotteTigre">
              <p
                class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  p-2 text-[0.90rem] md:text-[1rem]">
                L\'offre la plus adaptée à votre recherche est chez :</p>
            </div> -->
            <Chat_loader v-if="messageFinal && !showAffirmationTadaam" class="ml-16" />
          
          </section>

          <section v-if="showAffirmationTadaam" class="flex flex-col gap-3">
            <div>
              <div class="flex items-center gap-6">
                <img
                  :class="{ 'opacity-0': messageFin5 }"
                  class=" del opacity-0 w-8 h-8 lg:w-10 lg:h-10 m-0"
                  src="../assets/leo.png"
                  alt="mascotteTigre"
                />
              </div>
              <p class="mt-[-2rem] flex justify-center items-center">
                <img class="ml-12 w-80 h-72 lg:w-96 lg:h-80 rounded-md" src="../assets/mascottesleo/tatdam_haut.png" alt="Détective"/>
              </p>
            </div>
            <Chat_loader
              v-if="messageFinal && showAffirmationTadaam && !messageFin5"
              class="ml-16"
            />
          </section>
          <section class="flex flex-col gap-3">
            <div class="flex items-center gap-6" v-if="messageFin5">
              <img :class="{ 'opacity-0': messageFin }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0 " src="../assets/leo.png"
                alt="mascotteTigre">
              <div class="">
                <p v-if="bouyguesImage"
                  class="chat_bubble_question animation_chat p-2 w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw] text-[0.90rem] md:text-[1rem]">
                  L'offre la plus adaptée à votre recherche est chez :
                  <img class="mx-auto mt-[1rem] w-[88px] h-[88px]" src="../assets/bouygues.png" alt="mascotteTigre">
                </p>
                <p v-if="freeImage"
                  class="chat_bubble_question animation_chat w-full p-2 text-[0.90rem] md:text-[1rem]">
                  Bonne nouvelle !! Vous pouvez bénéficier de notre offre exclusive !
                  <img class="mx-auto mt-[1rem] w-[88px] h-[88px]" src="../assets/ImageFree.png" alt="mascotteTigre">
                </p>
              </div>
            </div>
            <Chat_loader v-if="messageFinal && messageFin5 && !messageFin" class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3">
            <div class="flex items-center gap-6" v-if="messageFin">
              <img :class="{ 'opacity-0': messageFin2 }" class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0"
                src="../assets/leo.png" alt="mascotteTigre">
              <div
                class="animation_chat1 chat-bubble p-2 text-[0.90rem] md:text-[1rem] w-[80vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  ">
                <p class=""> Vous profiterez de :</p>
                <ul class="ml-5 mr-4 mt-3">
                  <li class="text-green-600 font-semibold"><span class="font-bold pr-1">.</span>La
                    meilleure offre
                    internet du
                    moment !</li>
                  <li v-if="freeImage" class="text-green-600 font-semibold mt-2 mb-2"><span class="font-bold pr-1">.</span>Le
                    meilleur Wifi
                    </li>
                  <li v-if="bouyguesImage" class="text-green-600 font-semibold mt-2 mb-2"><span class="font-bold pr-1">.</span>Le
                    meilleur Wifi depuis 5 ans
                    </li>
                  <li class="text-green-600 font-semibold"><span class="font-bold pr-1">.</span>Et du
                    meilleur
                    rapport qualité/prix
                    du marché.</li>
                </ul>
              </div>
            </div>
            <Chat_loader v-if="messageFinal && messageFin5 && messageFin && !messageFin2" class="ml-16" />
          </section>
          <section class="flex flex-col gap-3">
            <div class="flex items-center gap-6" v-if="messageFin2">
              <img :class="{ 'opacity-0': messageFinal && messageFin5 && messageFin && messageFin2 && messageFin3 }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre">
              <p class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem]"
                v-if="bouyguesImage"> {{ FinalmesssageTime }} </p>
              <p class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem]"
                v-if="freeImage"> {{ FinalmesssageTime }}
              </p>
            </div>
            <Chat_loader v-if="messageFinal && messageFin5 && messageFin && messageFin2 && !messageFin3"
              class="ml-16" />
          </section>

          <section class="flex flex-col gap-3"
            :class="{ 'mb-[-0.1rem]': FinalmesssageTime2 == 'Soyez prêt(e) à décrocher ! 📞' && freeImage }">
            <div class="flex items-center gap-6" v-if="messageFin3">
              <img
                :class="{ 'opacity-0': (bouyguesImage || freeImage) && (FinalmesssageTime2 == 'Soyez prêt(e) à décrocher ! 📞' || FinalmesssageTime2 != 'Soyez prêt(e) à décrocher ! 📞') && (messageFin4 || messageFin6) }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre">
              <p v-if="bouyguesImage"
                class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw]  xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem]">
                {{ FinalmesssageTime2 }}</p>
              <p v-if="freeImage"
                class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem]">
                {{ FinalmesssageTime2 }}
              </p>
            </div>
            <Chat_loader
              v-if="(bouyguesImage && messageFin2 && messageFin && messageFin5 && messageFin3 && !messageFin4 && verifyMessage) || (bouyguesImage && messageFin2 && messageFin && messageFin5 && messageFin3 && !messageFin6 && !verifyMessage) || (freeImage && messageFin2 && messageFin && messageFin5 && messageFin3 && !messageFin4 && !verifyMessage)"
              class="ml-16 " />

          </section>
          <section class="flex flex-col gap-3 ">
            <div class="flex items-center gap-6"
              v-if="messageFin6 && FinalmesssageTime2 != 'Soyez prêt(e) à décrocher ! 📞'">
              <img
                :class="{ 'opacity-0': bouyguesImage && FinalmesssageTime2 != 'Soyez prêt(e) à décrocher ! 📞' && messageFin3 && messageFin6 && messageFin7 }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre">
              <p
                class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem]">
                {{ FinalmesssageTime3 }}</p>
            </div>
            <Chat_loader
              v-if="bouyguesImage && FinalmesssageTime2 != 'Soyez prêt(e) à décrocher ! 📞' && messageFin3 && messageFin6 && !messageFin8"
              class="ml-16 " />
          </section>
          <section class="flex flex-col gap-3"
            :class="{ 'mt-[-0.55rem]': FinalmesssageTime2 == 'Soyez prêt(e) à décrocher ! 📞' && bouyguesImage }">
            <div class="flex items-center gap-6" v-if="messageFin4">
              <img
                :class="{ 'opacity-0': (bouyguesImage || freeImage) && messageFin10 && (verifyMessage || !verifyMessage) }"
                class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre">
              <p
                class="chat_bubble_question animation_chat w-[80vw] mt-1 sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw] p-2 text-[0.90rem] md:text-[1rem]">
                De mon côté, je vous souhaite une belle journée et, vivez pleinement votre nouvelle expérience internet ! 🤗</p>
            </div>
            <Chat_loader
              v-if="(bouyguesImage || freeImage) && messageFin4 && !messageFin10 && (verifyMessage || !verifyMessage)"
              class="ml-16 " />
          </section>

          <section class="flex items-center gap-6" v-if="messageFin10">
            <img class="animation_chat del w-8 h-8 lg:w-10 lg:h-10 m-0" src="../assets/leo.png" alt="mascotteTigre" />
            <div
              class="chat_bubble_question animation_chat w-[80vw] sm:w-[58vw] md:w-[54vw] xl:w-[51vw] 2xl:[52vw]  p-2 text-[0.90rem] md:text-[1rem]">
              <p>Et bien sûr si vous avez aimé, pensez à partager ! 😉 Merci. </p>
              <div class="flex gap-5 lg:flex-row lg:gap-12 justify-center mt-8">
                <button @click="shareOnFacebook(conversation_modele)" class="flex gap-1 mb-2 lg:mb-0 items-center">
                  <p class="hidden xl:block">Facebook</p>
                  <img class="md:w-16 w-8 md:h-16 md:mt-[-0.2rem] h-8 " src="../assets/facebook.png" alt="facebook">
                </button>
                <button @click="shareOnTwitter(conversation_modele)" class="flex mb-2 lg:mb-0 items-center">
                  <p class="hidden xl:block">Twitter</p>
                  <img class="md:w-16 w-8 md:h-16 md:mt-[-0.2rem] h-8 " src="../assets/twitter.png" alt="twitter">
                </button>
                <button @click="shareOnWhatsApp(conversation_modele)" class="flex gap-1 mb-2 lg:mb-0 items-center">
                  <p class="hidden xl:block">WhatsApp</p>
                  <img class="md:w-16 w-8 md:h-16 md:mt-[-0.2rem] h-8 " src="../assets/whatsapp.png"
                    alt="shareOnWhatsApp">
                </button>
                <button @click="shareOnInstagram(conversation_modele)" class="flex gap-1 mb-2 lg:mb-0 items-center">
                  <p class="hidden xl:block">Instagram</p>
                  <img class="md:w-16 w-8 md:h-16 md:mt-[-0.2rem] h-8 " src="../assets/instagram.png" alt="instagram">
                </button>
                <button @click="shareOnTiktok(conversation_modele)" class="flex gap-1 mb-2 lg:mb-0 items-center mr-8">
                  <p class="hidden xl:block">Tiktok</p>
                  <img class="md:w-16 w-8 md:h-16 md:mt-[-0.2rem] h-8 " src="../assets/tiktok.png" alt="tiktok">
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- Modal de relance de l'utilisateur -->
    <relanceModal v-if="openRelanceModal" @close="closeRelanceModal()" :relance1="relance1" :relance2="relance2"
      :relance3="relance3" />
    <!-- Modal d'au revoir -->
    <GoodByeModal @close="closeGoodByeModal()" v-if="openGoodByeModal" />
    <Toctoc @close="closeGoodByeModal()" v-if="openToctocModal" />
    <PupUpAppel @close="closeGoodByeModal()" v-if="openPupUpAppelModal" />
    <!-- Modal de consentement aux cookies -->
    <cookiesModal class="cookiesAnimation" @close="closeCookiesModal()" v-if="openCookiesModal" />
    <img @click="showCookiesModal()" v-if="!openCookiesModal && showIconCookies"
      class="fixed bottom-[6%] lg:bottom-[8%] sm:bottom-[8%] md:bottom-[16%] w-10 md:w-12  lg:w-18 h-10 md:h-12  lg:h-18 left-0 -[10%]"
      src="../assets/tigre_cookies.png" alt="tigre_cookies">
  </main>

  <!-- le pied de page de l'interface de conversation -->
  <chatFooter />

  <!-- Loader au chargement de la page -->
  <Initial_loader v-if="showInitialLoader" class="loaderStyle" />
</template>

<script setup>

/***
 * Importations des ressources nécessaires
 */
import { onMounted, ref, watch } from 'vue';
import config from "@/main.js";
import axios from 'axios';
// Importation du loader à afficher au chargement de la page
import Initial_loader from '@/components/First_loader.vue'
// Importation du loader à afficher lorsqu'un message est en cours de chargement
import Chat_loader from '@/components/chat_loader.vue'

import chatHeader from '@/components/chatbot/chatbotHeader.vue';
import chatFooter from '@/components/chatbot/chatbotFooter.vue';
import relanceModal from '@/components/chatbot/RelanceModal.vue';
import cookiesModal from '@/components/chatbot/CookiesModal.vue';
import GoodByeModal from '@/components/chatbot/GoodByeModal.vue';
import Toctoc from '@/components/chatbot/Toctoc.vue';
import PupUpAppel from '@/components/chatbot/PupUpAppel.vue';


/********************  Modal de relance *********************/

/***
 * Déclaration des variables
 */
const relance1 = ref(false)
const relance2 = ref(false)
const relance3 = ref(false)
const openRelanceModal = ref(false)
const showIconCookies = ref(false)
let clearInactivity = ref(null)
let isDisabled = ref(true)

//données pour les formulaires
const numeroError = ref('');
const showError = ref('');
const numero_client = ref(null)
const firstAgreement = ref(false)
const fournisseur = ref(null)
const engagement = ref(null)
const motivation = ref(null)
const fibre_ou_adsl = ref(null)
const cout = ref(null)
// const secondAgreement = ref(null)
const codePostal = ref(null)


/***
 * Méthodes
 */

// Fermer  le modal de relance
const closeRelanceModal = () => {
  openRelanceModal.value = false
}

/********************  Modal des cookies *********************/

/***
 * Déclaration des variables
 */
const openCookiesModal = ref(false);
setTimeout(() => {
  openCookiesModal.value = true
}, 10000);

/**fin**/

// L'ensemble des fonctions suivantes sont pour gérer l'interaction des utilisateurs sur le bouton je découvre mon offre
const displayText = ref('Je découvre mon offre');
function handleInteraction() {
  if (isDisabled.value) {
    displayText.value = 'Veuiller cocher la case des CGU';
  } else {
    displayText.value = 'Je découvre mon offre';
  }
}

function handleMouseLeave() {
  if (!isDisabled.value && firstAgreement.value) {
    displayText.value = 'Je découvre mon offre';
  }
  else if (isDisabled.value && !firstAgreement.value) {
    displayText.value = 'Je découvre mon offre';
  }
}

watch(firstAgreement, (newVal) => {
  if (newVal) {
    isDisabled.value = false;
  } else {
    isDisabled.value = true;
  }
});

watch(isDisabled, (newVal) => {
  if (!newVal) {
    displayText.value = 'Je découvre mon offre';
  }
});



///------fin------///

// Affiche le modal des cookies
const showCookiesModal = () => {
  openCookiesModal.value = true
  showIconCookies.value = false
}
// Fermer le modal des cookies
const closeCookiesModal = () => {
  openCookiesModal.value = false
  showIconCookies.value = true
}

/********************  Modal d'au revoir *********************/

/***
 * Déclaration des variables
 */
// const openGoodByeModal = ref(false);
const openToctocModal = ref(false);
const openPupUpAppelModal = ref(false);

/***
 * Méthodes
 */

// Fermer le modal des cookies
const closeGoodByeModal = () => {
  openPupUpAppelModal.value = false
  // openGoodByeModal.value = false
  openToctocModal.value = false
  clearTimeout(clearInactivity.value)
  clearTimeout(setIntervalDelete.value);

}
/**fin**/

const bouyguesImage = ref(false)
const freeImage = ref(false)
const messageFin = ref(false)
const messageFin2 = ref(false)
const messageFin3 = ref(false)
const messageFin4 = ref(false)
const messageFin5 = ref(false)
const messageFin6 = ref(false)
const messageFin7 = ref(false)
const messageFin8 = ref(false)
const messageFin9 = ref(false)
const messageFin10 = ref(false)
const show1 = ref(true)
const show2 = ref(false)
const show3 = ref(false)
const showAnswerButton = ref(true)
const showQuestion = ref(false)
const messageFinal = ref(false)


setTimeout(() => {
  show2.value = true;
}, 4000);
setTimeout(() => {
  show3.value = true;
}, 6000);


const currentStep = ref(false);
const nextStep = () => {
  // Validation logic


  if (!numero_client.value) {
    numeroError.value = 'Veuillez entrer un numéro à 10 chiffres.';
  } else if (!isValidPhoneNumber(numero_client.value)) {
    numeroError.value = 'Veuillez saisir un numéro de téléphone valide à 10 chiffres .';
  } else {
    numeroError.value = '';
  }

  // Check if all fields are valid
  if (isValidPhoneNumber(numero_client.value)) {
    currentStep.value++;
    showAnswerButton.value = true
    showSuivantButton.value = false
    return true
  } else {
    currentStep.value = false;
    showAnswerButton.value = false
    return false
  }
}

const setIntervalDelete = ref(null)
const showSuivantButton = ref(true)
// variable de controle du scrool
const pageContainer = ref(null);
// Variable affichant ou non le loader initial
const showInitialLoader = ref(true)
// Variable affichant ou non les message du bot
const showBotAnswer = ref(false)
// Variable affichant ou non le loader lors du chargement d'un message
const beginMsg = ref([" Bonjour, moi c'est Léo, votre bot Internet !", "A la recherche de la meilleure offre ? Vous êtes au bon endroit !!! 🤗. En plus d'un"])
const endMsg = ref(null)
const firstQuestion = ref('Pourquoi changer de fournisseur ?')
const suggested_answers = ref([
  { answer: 'un top réseau', answerImg: require('@/assets/top_reseau.png') },
  { answer: 'Un bouquet adapté', answerImg: require('@/assets/bouquet.png') },
  { answer: 'Faire des économies', answerImg: require('@/assets/Prix.png') },
  { answer: 'Des conseillers disponibles', answerImg: require('@/assets/conseiller.png') },
  { answer: 'Mon déménagement', answerImg: require('@/assets/demenagement.png') },
  { answer: 'Je n\'ai pas de fournisseur', answerImg: require('@/assets/Pas_de_fournisseur.png') },
  { answer: 'Autre', answerImg: require('@/assets/three_dot.png') }
])
const error = ref(null)
const answerChosen = ref(null)
const conversation_id = ref(null)
const visitor_id = ref(null)
const botAnswer = ref([])
const isCodePostalForm = ref(false)
const isOfferForm = ref(false)
const showCGUmsg = ref(false)
const historyMessage = ref({})
const chatHistory = ref([])
const count = ref(0)
// const modalAdsl = ref(false)
// const modalOui = ref(false)
const offer = ref(null)
const conversation_modele = ref(null)
const weight = ref(0)
const goNext = ref(false)

const checkInactivity = () => {
  // Vérifier si le compteur d'inactivité a atteint la limite
  if (count.value >= 300) {  
    openRelanceModal.value = true;
  }
};

function gtag_report_conversion() {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16665409358/7dlpCIrujtAZEM7u14o-',
    });
  }
}


function handleButtonClick(answer) {
    if (answer === 'Je découvre mon offre') {
      gtag_report_conversion();
    }
    getAnswerChosen(answer);
}


// Utiliser setTimeout pour vérifier l'inactivité après un certain temps
const startInactivityCheck = () => {
  clearInactivity.value = setTimeout(checkInactivity, 300000);
};

const provenance = localStorage.getItem('provenance');
const beginDiscussion = (question, answer, provenance) => {
 axios.post(`${config.BASE_URL}/chatApi/init_discussion.php`, {
   question: question,
   answer: answer,
   provenance: provenance
 }).then((response) => {
   conversation_id.value = response.data.conversation_id
   visitor_id.value = response.data.visitor_id
 })
}

onMounted(() => {
  startInactivityCheck();
  resetActivityTimeout();

  setTimeout(() => {
    showInitialLoader.value = false
  }, 2000);
  setTimeout(() => {

    setTimeout(() => {
      showQuestion.value = true
      showBotAnswer.value = true
    }, 6000);

    endMsg.value = '';
    historyMessage.value = { role: "bot", welcomeMsg: beginMsg.value, question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: [] }

    return historyMessage
  }, 2000)

})

// fonction qui gère le scroll automatique du chatbot
const scrollToBottom = () => {
  if (pageContainer.value) {
    pageContainer.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }
};




/**fonctions pour enregistrer les actions de l'utilisateur**/

const tracking_action = (conversation_id, visitor_id, question, answer, provenance) => {
 axios.post(`${config.BASE_URL}/chatApi/tracking_action.php`, {
   conversation_id: conversation_id,
   visitor_id: visitor_id,
   question: question,
   answer: answer,
   provenance: provenance,
 })

}

const end_discussion = (conversation_id, visitor_id, question, answer) => {
 axios.post(`${config.BASE_URL}/chatApi/end_discussion.php`, {
   conversation_id: conversation_id,
   visitor_id: visitor_id,
   question: question,
   answer: answer,
 })
}

/**Fonctions gérant la validité des données du formulaire de découverte d'offre**/
const submitForm = () => {

  if (!firstAgreement.value) {
    showError.value = true;
    return false
  } else {
    showError.value = '';
    return true
  }

};

const isValidPhoneNumber = (phoneNumber) => {
  // const phoneRegex = /^(01|02|03|04|05|06|07|08|09)\d{8}$/
  const phoneRegex = /^(01|02|03|04|05|06|07|08|09)(?!.*00000)\d{8}$/
  return phoneRegex.test(phoneNumber);
};

/**Fin**/

// const affirmation = ref("")
const showAffirmation = ref("")
const showAffirmation1 = ref(false)
const showAffirmation2 = ref(false)
const showAffirmation3 = ref(false)
const showAffirmation4 = ref(false)
const showAffirmation5 = ref(false)
const showAffirmation6 = ref(false)
const showAffirmation7 = ref(false)
const showAffirmationTadaam = ref(false);
const showAnswerLoader = ref(false)



//Cette fonction permet de renvoyer la question suivante et d'assurer le maintien du fil de la discussion
const getAnswerChosen = async (answer) => {


  clearInterval(setIntervalDelete.value)
  //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de code postal
  // const provenance = localStorage.getItem('provenance');
  // console.log(provenance)
if (answer == 'Je découvre mon offre') {

    if (!numero_client.value) {
      numeroError.value = 'Veuillez entrer un numéro à 10 chiffres.';
      return;
    } else if (!isValidPhoneNumber(numero_client.value)) {
      numeroError.value = 'Le format du numéro de téléphone est invalide.';
      return;
    } else {
      numeroError.value = '';
    }
    
    //le bloc suivant est utilisé pour savoir la logique à suivre après le formulaire de découverte offre
    if (submitForm()) {
      showQuestion.value = false
      isOfferForm.value = false
      showCGUmsg.value = false

      axios.post(`${config.BASE_URL}/chatApi/index.php`, {
       telephone: numero_client.value,
       consentement: firstAgreement.value == true ? "Oui" : "Non",
       // scd_agreed: secondAgreement.value,
       fournisseur: fournisseur.value,
       motivation: motivation.value,
       engagement: engagement.value,
       lead_id: conversation_id.value,
       needWhatsAppApproval: 0,
       provenance: localStorage.getItem('provenance')
      }).then((response) => {
       console.log(response);
      })

      tracking_action(conversation_id.value, visitor_id.value, firstQuestion.value, answer, provenance)

      chatHistory.value.push(historyMessage.value)
      chatHistory.value.push({ role: "user", content: answer })
      localStorage.setItem('chatHistory', chatHistory)
      beginMsg.value = []
      showAnswerLoader.value = true
      showBotAnswer.value = false
      setTimeout(() => {


        //charger l'étape suivante
        showAnswerLoader.value = false
        showBotAnswer.value = true
        showQuestion.value = false
        messageFinal.value = true
        weight.value++
        botAnswer.value = []

        switch (weight.value) {

          case 4:
            window.removeEventListener('mousemove', resetActivityTimeout);
            window.removeEventListener('keydown', resetActivityTimeout);
            firstQuestion.value = 'L\'offre la plus adaptée à votre recherche est chez :'
            console.log(firstQuestion.value)
            break
        }

        if (firstQuestion.value.indexOf("L'offre la plus adaptée à votre recherche est chez :") !== -1) {
          end_discussion(conversation_id.value, visitor_id.value, firstQuestion.value, answerChosen.value)
          window.removeEventListener('mousemove', resetActivityTimeout);
          window.removeEventListener('keydown', resetActivityTimeout);
          const AnswerQuestionThree = ref("")

          if (chatHistory.value[2].question == "Et vous êtes chez qui actuellement ?") {
            AnswerQuestionThree.value = chatHistory.value[3].content

          } else {
            AnswerQuestionThree.value = ''

          }


          if (AnswerQuestionThree.value == 'BOUYGUES') {
            offer.value = 'FREE'
          }
          else {
            offer.value = 'BOUYGUES'
          }

          console.log(offer.value)

          setTimeout(() => {
            if (offer.value == 'BOUYGUES') {

              showAffirmationTadaam.value = true
              bouyguesImage.value = true
              freeImage.value = false
              setTimeout(() => {
                getDiscussionTime()

                setTimeout(() => {
                  // messageFin.value = true
                  messageFin5.value = true
                }, 5000)
                setTimeout(() => {
                  messageFin.value = true
                  // messageFin5.value = true
                }, 7000)
                setTimeout(() => {
                  messageFin2.value = true
                }, 10000)
                
                setTimeout(() => {
                  messageFin3.value = true
                }, 12000);
                if (verifyMessage.value) {
                  setTimeout(() => {
                    messageFin4.value = true
                  }, 14000);

                  setTimeout(() => {
                    window.removeEventListener('mousemove', resetActivityTimeout);
                    window.removeEventListener('keydown', resetActivityTimeout);
                    messageFin9.value = true
                  }, 16000);

                  setTimeout(() => {
                    messageFin10.value = true
                    clearInterval(setIntervalDelete.value);
                    clearTimeout(clearInactivity.value)
                    window.removeEventListener('mousemove', resetActivityTimeout);
                    window.removeEventListener('keydown', resetActivityTimeout);
                    const counterFinal = ref(0)
                    setInterval(() => {
                      counterFinal.value++;
                      if (counterFinal.value == 5) {
                        // openGoodByeModal.value = true;
                        openRelanceModal.value = false;
                        window.removeEventListener('mousemove', resetActivityTimeout);
                        window.removeEventListener('keydown', resetActivityTimeout);
                      }
                    }, 1000);
                  }, 18000);
                } else {
                  setTimeout(() => {
                    messageFin6.value = true
                  }, 3000);
                  // setTimeout(() => {
                  //   messageFin7.value = true
                  // }, 8000);
                  setTimeout(() => {
                    messageFin8.value = true
                    window.removeEventListener('mousemove', resetActivityTimeout);
                    window.removeEventListener('keydown', resetActivityTimeout);
                  }, 8000);


                  setTimeout(() => {
                    messageFin4.value = true
                  }, 15000);

                  setTimeout(() => {
                    messageFin9.value = true
                  }, 18000); // 23000


                  setTimeout(() => {
                    messageFin10.value = true

                    clearInterval(setIntervalDelete.value);
                    clearTimeout(clearInactivity.value)
                    const counterFinal = ref(0)
                    setInterval(() => {
                      counterFinal.value++;
                      if (counterFinal.value == 5) {
                        // openGoodByeModal.value = true;
                        openRelanceModal.value = false;
                        window.removeEventListener('mousemove', resetActivityTimeout);
                        window.removeEventListener('keydown', resetActivityTimeout);
                      }
                    }, 1000);
                  }, 28000);
                }

              }, 3000);
            }


            if (offer.value == 'FREE') {
              
              showAffirmationTadaam.value = true;
              freeImage.value = true
              bouyguesImage.value = false

              

              setTimeout(() => {
                getDiscussionTime()
                messageFin5.value = true
              }, 7000);
              setTimeout(() => {
                getDiscussionTime()
                messageFin.value = true
              }, 9000);
              setTimeout(() => {
                getDiscussionTime()
                messageFin2.value = true
              }, 11000);
              setTimeout(() => {
                messageFin3.value = true
              }, 15000);
              setTimeout(() => {
                messageFin4.value = true
              }, 20000);

              setTimeout(() => {
                messageFin9.value = true
              }, 25000);

              setTimeout(() => {
                messageFin10.value = true

                clearInterval(setIntervalDelete.value);
                clearTimeout(clearInactivity.value)
                const counterFinal = ref(0)
                setInterval(() => {
                  counterFinal.value++;
                  if (counterFinal.value == 5) {
                    // openGoodByeModal.value = true;
                    openRelanceModal.value = false;
                    window.removeEventListener('mousemove', resetActivityTimeout);
                    window.removeEventListener('keydown', resetActivityTimeout);
                  }
                }, 5000);
              }, 30000);
            }
          }, 7000);
        }

        answerChosen.value = null

        historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
        return historyMessage, isOfferForm, offer

      }, 1500);

      scrollToBottom();
      return historyMessage, isOfferForm
    } else {
      isOfferForm.value = true
      showCGUmsg.value = true
    }
  }
  else {
    // if (answer == 'ADSL' || answer == 'FIBRE') {
    //   modalAdsl.value = true
    // }
    // if ((answer == 'Oui' || answer == 'Non') && firstQuestion.value == 'Voyons à quelle technologie vous êtes éligible ! Votre code postal s’il vous plait ?') {
    //   modalOui.value = true
    //   showAnswerButton.value = false
    // }
    console.log(firstQuestion.value)
    showQuestion.value = false

    if (firstQuestion.value == 'Pourquoi changer de fournisseur ?') {
      motivation.value = answer
      console.log('here is the firstquestion')
      beginDiscussion(firstQuestion.value, answer, provenance)
    } else {
      tracking_action(conversation_id.value, visitor_id.value, firstQuestion.value, answer, provenance)
    }

    answerChosen.value = answer
    chatHistory.value.push(historyMessage.value)
    chatHistory.value.push({ role: "user", content: answer })
    localStorage.setItem('chatHistory', chatHistory)
    beginMsg.value = []
    showAnswerLoader.value = true
    showBotAnswer.value = false
    setTimeout(() => {
      showAnswerLoader.value = false
      showBotAnswer.value = true

      if (answerChosen.value == "Je n'ai pas de fournisseur") {
        goNext.value = true;
        weight.value = 3; // Fixer directement la valeur à 3
      } else if (goNext.value == true) {
        // Si ce n'est pas 'Je n'ai pas de fournisseur', on suit la logique habituelle
        if (firstQuestion.value == "Notez vos coordonnées pour découvrir votre offre ! ") {
          weight.value++;
        } else if (
          firstQuestion.value == "Pourquoi changer de fournisseur ?"
        ) {
          weight.value = 3; // Ici, pour éviter de ramener à la première question, on met directement weight à 3
        }
      } else {
        // Sinon, on incrémente juste weight normalement
        weight.value++;
      }

      switch (answerChosen.value) {
        case 'Moins 25 €':
          cout.value = answerChosen.value
          botAnswer.value = ['Je peux sûrement vous proposer moins cher avec de belles options !', 'Très bien, c’est noté. Alors continuons…']
          break
        case 'De 25 € à 35 €':
          cout.value = answerChosen.value
          botAnswer.value = ['Je vois que vous recherchez la qualité au meilleur prix !']
          break
        case '35 € à 50 €':
          cout.value = answerChosen.value
          botAnswer.value = ['Très bien, je vois que vous recherchez une offre complète et fiable !', 'Très bien, c’est noté. Alors continuons…']
          break
        case 'Plus de 50 €':
          cout.value = answerChosen.value
          botAnswer.value = ['Je vais donc vous orienter vers la Rolls de la box !', 'Très bien, c’est noté. Alors continuons…']
          break
        case 'Je ne sais pas':
          botAnswer.value = (firstQuestion.value == 'Une idée de budget pour votre prochaine box ?') ? ['Dans ce cas, le plus simple c’est d’en parler avec notre expert pour cerner votre besoin !'] :
            (firstQuestion.value == 'Vous êtes actuellement en Fibre ou en ADSL ?') ? '' :
              (firstQuestion.value == 'Toujours engagé(e) ?') ? ['C’est une information importante. Elle est visible sur votre espace client ou sur votre dernière facture. Elle permet à notre expert d’être plus précis dans sa proposition.'] : ''
          if (firstQuestion.value == 'Une idée de budget pour votre prochaine box ?') {
            cout.value = answerChosen.value
          }
          else if (firstQuestion.value == 'Toujours engagé(e) ?') {
            engagement.value = answerChosen.value
          }
          else if (firstQuestion.value == 'Vous êtes actuellement en Fibre ou en ADSL ?') {
            fibre_ou_adsl.value = answerChosen.value
          }
          break
        case 'Oui, moins d\' 1 an':
          engagement.value = answerChosen.value
          botAnswer.value = ['Bonne nouvelle !😃, votre nouvel opérateur prend en charge une partie des frais de résiliation.']
          break
        case 'Oui, plus d\' 1 an':
          engagement.value = answerChosen.value
          botAnswer.value = ['Bonne nouvelle !😃, votre nouvel opérateur prend en charge une partie des frais de résiliation.']
          break
        case 'Non':
          botAnswer.value = (firstQuestion.value == 'Toujours engagé(e) ?') ? ['Super, 😃 vous pouvez changer d\'offre sans frais !'] : ['Merci pour toutes ces informations. ', "Casquette de détective activée 🧢 pour dénicher votre offre ! 🧐"]

          if (firstQuestion.value == 'Toujours engagé(e) ?') {
            engagement.value = answerChosen.value

          }
          break
        case 'FIBRE':
          botAnswer.value = []
          fibre_ou_adsl.value = answerChosen.value
          break
        case 'ADSL':
          botAnswer.value = []
          fibre_ou_adsl.value = answerChosen.value
          break
        case 'AUTRE':
          botAnswer.value = []
          fibre_ou_adsl.value = answerChosen.value
          break
        case 'FREE':
          localStorage.setItem("AnswerQuestionThree", "FREE")
          botAnswer.value = []
          fournisseur.value = answerChosen.value
          break
        case 'BOUYGUES':
          localStorage.setItem("AnswerQuestionThree", "BOUYGUES")
          botAnswer.value = []
          fournisseur.value = answerChosen.value
          break
        case 'SFR/Red by SFR':
          localStorage.setItem("AnswerQuestionThree", "SFR/Red by SFR")
          botAnswer.value = []
          fournisseur.value = answerChosen.value
          break
        case 'ORANGE/SOSH':
          localStorage.setItem("AnswerQuestionThree", "ORANGE/SOSH")
          botAnswer.value = []
          fournisseur.value = answerChosen.value
          break

      }
      switch (weight.value) {
        // case 1:
        //   firstQuestion.value = 'Une idée de budget pour votre prochaine box ?'
        //   suggested_answers.value = [{ answer: 'Moins 25 €', answerImg: null }, { answer: 'De 25 € à 35 €', answerImg: null }, { answer: '35 € à 50 €', answerImg: null }, { answer: 'Plus de 50 €', answerImg: null }, { answer: 'Je ne sais pas', answerImg: null }]
        //   break
        case 1:
          firstQuestion.value = 'Et vous êtes chez qui actuellement ?'
          suggested_answers.value = [
            { answer: 'FREE', answerImg: require('@/assets/logo_free.png') },
            { answer: 'BOUYGUES', answerImg: require('@/assets/logo_bytel.png') },
            { answer: 'SFR/Red by SFR', answerImg: require('@/assets/logo_sfr.png') },
            { answer: 'ORANGE/SOSH', answerImg: require('@/assets/Logo_Orange_QeoW3Cf.png') },
            { answer: 'AUTRE', answerImg: require('@/assets/three_dot.png') },
          ]
          break
        // case 2:
        //   firstQuestion.value = 'Vous êtes plutôt en Fibre ou en ADSL aujourd’hui ?'
        //   suggested_answers.value = [
        //     { answer: 'ADSL', answerImg: require('@/assets/adsl.png') },
        //     { answer: 'FIBRE', answerImg: require('@/assets/fibre_logo.png') },
        //     { answer: 'Je ne sais pas', answerImg: require('@/assets/ne_sait_pas.png') },
        //   ]
        //   break
        case 2:
          firstQuestion.value = 'Toujours engagé(e) ?'
          suggested_answers.value = [{ answer: 'Oui, moins d\' 1 an' }, { answer: 'Oui, plus d\' 1 an', answerImg: null }, { answer: 'Non', answerImg: null }, { answer: 'Je ne sais pas', answerImg: null }]
          break
        // case 3:
        //   firstQuestion.value = 'Voyons à quelle technologie vous êtes éligible ! Votre code postal s’il vous plait ?';
        //   suggested_answers.value = [{ answer: 'SUIVANT', answerImg: null }];
        //   break

        case 3:
          botAnswer.value = ['Merci pour toutes ces informations. ', "Casquette de détective activée 🧢 pour dénicher votre offre ! 🧐",]
          showAnswerButton.value = false
          firstQuestion.value = 'Notez vos coordonnées pour découvrir votre offre ! '
          suggested_answers.value = [{ answer: 'Je découvre mon offre', answerImg: null }]

          if (botAnswer.value[0] == 'Merci pour toutes ces informations. ') {
          showAffirmation1.value = false
          showAffirmation2.value = false
          showAffirmation3.value = false
          showAffirmation4.value = false
          showAffirmation5.value = true
          setTimeout(() => {
            showAffirmation6.value = true
          }, 1500);
          setTimeout(() => {
            showAffirmation7.value = true
          }, 5000);
          }

          setTimeout(() => {
            showQuestion.value = true
          }, botAnswer.value.length * 2000)


          isOfferForm.value = true
          answerChosen.value = null

          historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
          scrollToBottom();
          break
      }

      // if (answerChosen.value == "Je n'ai pas de fournisseur") {
      //   goNext.value = true
      // }

      if (botAnswer.value.length > 0) {
        if (botAnswer.value[0] == 'Je peux sûrement vous proposer moins cher avec de belles options !' || botAnswer.value[0] == 'Je vois que vous recherchez la qualité au meilleur prix !' || botAnswer.value[0] == 'Très bien, je vois que vous recherchez une offre complète et fiable !' || botAnswer.value[0] == 'Je vais donc vous orienter vers la Rolls de la box !' || botAnswer.value[0] == 'Dans ce cas, le plus simple c’est d’en parler avec notre expert pour cerner votre besoin !') {
          showAffirmation.value = botAnswer.value[0]
          showAffirmation1.value = true
          showAffirmation3.value = false
          showAffirmation4.value = false
          showAffirmation5.value = false
          showAffirmation6.value = false
          showAffirmation7.value = false
          setTimeout(() => {
            showAffirmation2.value = true
            showAffirmation3.value = false
            showAffirmation4.value = false
            showAffirmation5.value = false
            showAffirmation6.value = false
            showAffirmation7.value = false
          }, 1500);
        }
        if (botAnswer.value[0] == 'Bonne nouvelle !😃, votre nouvel opérateur prend en charge une partie des frais de résiliation.' || botAnswer.value[0] == "Super, 😃 vous pouvez changer d'offre sans frais !" || botAnswer.value[0] == "C’est une information importante. Elle est visible sur votre espace client ou sur votre dernière facture. Elle permet à notre expert d’être plus précis dans sa proposition.") {
          showAffirmation.value = botAnswer.value[0]
          showAffirmation1.value = false
          showAffirmation2.value = false
          showAffirmation3.value = true
          showAffirmation4.value = false
          showAffirmation5.value = false
          showAffirmation6.value = false
          showAffirmation7.value = false
        }

      }
      setTimeout(() => {
        showQuestion.value = true
      }, botAnswer.value.length * 1500)

      if (firstQuestion.value.indexOf('code postal') !== -1) {
        isCodePostalForm.value = true
      }

      if (firstQuestion.value.indexOf('découvrez votre offre') !== -1) {
        isOfferForm.value = true
      }

      answerChosen.value = null

      historyMessage.value = { role: "bot", welcomeMsg: [], question: firstQuestion.value, suggested_answers: suggested_answers.value, affirmation: botAnswer.value }
      scrollToBottom();

      return historyMessage, isCodePostalForm, isOfferForm

    }, 1500);
  }

}


/**
 * Méthode affichant les messages en fonction de l'heure
 */
const FinalmesssageTime = ref("");
const FinalmesssageTime2 = ref("");
const FinalmesssageTime3 = ref("");
// const FinalmesssageTime4 = ref("");
const verifyMessage = ref(false);

// fonction permettant de contrôler l'affichage des messages de fin
function getDiscussionTime() {
  // *Récupérer l'heure de Paris*
  var date = new Date();
  date.toLocaleDateString("fr-FR", {
    timeZone: "Europe/Paris",
  });

  var heure = date.getHours();
  var minutes = date.getMinutes();

  // Fonction pour afficher le message
  function showMessageTime(message) {
    FinalmesssageTime.value = message
  }

  // Déterminer le message à afficher en fonction de l'heure et du jour
    if ((heure >= 10 && heure < 19) || (heure === 19 && minutes <= 45)) {

      // Afficher le message d'ouverture du service
      if (bouyguesImage.value) {
        showMessageTime("J’envoie vos réponses à notre conseiller Bouygues et il vous appelle dans quelques minutes…");
        verifyMessage.value = true
        FinalmesssageTime2.value = "Soyez prêt(e) à décrocher ! 📞"
        window.removeEventListener('mousemove', resetActivityTimeout);
        window.removeEventListener('keydown', resetActivityTimeout);
      } else {
        showMessageTime("J’envoie vos réponses à notre conseiller expert et il vous appelle dans quelques minutes…");
        FinalmesssageTime2.value = "Soyez prêt(e) à décrocher ! 📞"
        window.removeEventListener('mousemove', resetActivityTimeout);
        window.removeEventListener('keydown', resetActivityTimeout);
      }

    }
    else {
      if (bouyguesImage.value) {
        showMessageTime("J’envoie vos réponses à notre conseiller Bouygues et il vous appelle dans quelques minutes…");
        verifyMessage.value = true
        FinalmesssageTime2.value = "Soyez prêt(e) à décrocher ! 📞"
        window.removeEventListener('mousemove', resetActivityTimeout);
        window.removeEventListener('keydown', resetActivityTimeout);
      } else {
        showMessageTime("J’envoie vos réponses à notre conseiller expert et il vous appelle dans quelques minutes…");
        FinalmesssageTime2.value = "Soyez prêt(e) à décrocher ! 📞"
        window.removeEventListener('mousemove', resetActivityTimeout);
        window.removeEventListener('keydown', resetActivityTimeout);
      }
    }
  }
  


const resetActivityTimeout = () => {
  count.value = 0;
  // Réinitialiser le minuteur de vérification d'inactivité
  clearTimeout(setIntervalDelete.value);
  // Redémarrer le minuteur de vérification d'inactivité
  setIntervalDelete.value = setInterval(() => {
    count.value++;
    switch (count.value) {
      case 45:
        openRelanceModal.value = true;
        relance1.value = true;
        relance2.value = false;
        relance3.value = false;
        break;

      case 90:
        openRelanceModal.value = true;
        relance2.value = true;
        relance3.value = false;
        relance1.value = false;
        relance3.value = false;
        break;

      case 420:
        openRelanceModal.value = true;
        relance2.value = false;
        relance1.value = false;
        relance3.value = true;
        clearInterval(setIntervalDelete.value);
        break;
    }
  }, 1000);
};

// Vérifier si une activité est en cours sur la page
window.addEventListener('mousemove', resetActivityTimeout);
window.addEventListener('keydown', resetActivityTimeout);


const chatbotUrl = "https://meilleursforfaitsinternet.fr/";

const shareOnFacebook = async (id) => {
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(chatbotUrl)}`;
  window.open(facebookShareLink, "_blank");

  // Enregistrez le partage dans la base de données Django
  await recordShare(id, 'facebook');
};

const shareOnTwitter = async (id) => {
  const textToTweet = "Meilleures offres disponibles";
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(chatbotUrl)}&text=${encodeURIComponent(textToTweet)}`;
  window.open(twitterShareLink, "_blank");

  // Enregistrez le partage dans la base de données Django
  await recordShare(id, 'twitter');
};

const shareOnWhatsApp = async (id) => {
  const whatsappShareLink = `https://wa.me/?text=${encodeURIComponent("Découvrez mon chatbot incroyable! " + chatbotUrl)}`;
  window.open(whatsappShareLink, "_blank");

  // Enregistrez le partage dans la base de données Django
  await recordShare(id, 'whatsapp');
};

const shareOnInstagram = async (id) => {
  const instagramProfileLink = "https://www.instagram.com/VOTRE_NOM_UTILISATEUR/";
  window.open(instagramProfileLink, "_blank");

  // Enregistrez le partage dans la base de données Django
  await recordShare(id, 'instagram');
};

const shareOnTiktok = async (id) => {
  const tiktokShareLink = `https://www.tiktok.com/share?url=${encodeURIComponent(chatbotUrl)}`;
  window.open(tiktokShareLink, "_blank");

  // Enregistrez le partage dans la base de données Django
  await recordShare(id, 'tiktok');
};

const recordShare = async (id, socialMedia) => {
  try {
    // Effectuez une requête POST vers votre backend pour enregistrer le partage
    await axios.post(`${config.BASE_URL}/api/share/${id}/${socialMedia}`);
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement du partage:', error);
  }

};


</script>


<style>
.clicked {
  background-color: red;
  /* Changez la couleur comme vous le souhaitez */
}

/**
Style d'apparition du modal des cookies
*/
.cookiesAnimation {
  animation: cookies 2s ease-out;
}

@keyframes cookies {
  from {
    transform: translateX(-30%);
  }

  to {
    transform: translateX(0%);
  }
}

main {
  font-size: 0.9rem
}

.error-message {
  color: red;
  margin-top: 5px;
}

.del {
  margin-left: 100px;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  transition: 0.3s ease-in-out;
}

.message-left {
  flex-direction: row;
}

.message-right {
  flex-direction: row-reverse;
}

.image {
  flex-shrink: 0;
  position: relative;
  margin-right: 2px;
  margin-left: 2px;
  /* Ajoutez cette ligne */
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-size: cover;
}

.chat-bubble {
  position: relative;
  background-color: white;
  border-radius: 20px 20px 20px 0;
}

.chat-bubble_answer {
  position: relative;
  background-color: white;
  border-radius: 20px 20px 20px 0;
}

.chat-bubble1 {
  position: relative;
  background-color: green;
  border-radius: 20px 20px 20px 0;
  color: white;
}

.chat_bubble_question {
  position: relative;
  background-color: white;
  border-radius: 20px 20px 20px 0;
}

.message-left .chat-bubble {
  background-color: white;
  border-radius: 20px 20px 0 20px;
}

.message-left .chat-bubble_answer {
  background-color: white;
  border-radius: 20px 20px 0 20px;
}

.message-right .chat-bubble {
  background-color: #0891b2;
  border-radius: 20px 20px 0 20px;
}

.message-right .chat-bubble_answer {
  background-color: #0891b2;
  border-radius: 20px 20px 0 20px;
}

.chat_bubble_question:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  transform: translateY(-60%) translateX(-50%) rotate(45deg);
}

.chat-bubble:before {
  content: '';
  position: absolute;
  left: 0;
  top: 58%;
  width: 10px;
  height: 10px;
  background-color: white;
  transform: translateY(-55%) translateX(-50%) rotate(45deg);
}

.message-right .chat-bubble:before {
  left: initial;
  right: 0;
  background-color: #0891b2;
  transform: translateY(-50%) translateX(50%) rotate(45deg);
}



.message-right .chat-bubble p {
  color: #fff;
}

.chat-bubble p:first-of-type {
  margin-top: 0;
}

.chat-bubble p:last-of-type {
  margin-bottom: 0;
}

.message-right .chat-bubble_answer p {
  color: #fff;
}

.chat-bubble_answer p:first-of-type {
  margin-top: 0;
}

.chat-bubble_answer p:last-of-type {
  margin-bottom: 0;
}

.chat-bubble1:before {
  content: '';
  position: absolute;
  left: 0;
  top: 58%;
  width: 13px;
  height: 13px;
  background-color: green;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}

.chat-bubble1 p:first-of-type {
  margin-top: 0;
}

.chat-bubble1 p:last-of-type {
  margin-bottom: 0;
}



.animation_chat {
  /* animation: anim_chat 0.1s ease-in-out; */
  animation: anim_chat 3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes anim_chat {
  0% {
    opacity: 0;
    transform: scale(0.3);
    /* Décalage pour la vibration */
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
    /* Décalage pour la vibration */
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animation_chat {
  animation: anim_chat 0.2s ease-in-out;
}

.animation_chat1 {
  animation: anim_chat 0.2s ease-in-out;
}


.animation_chat2 {
  animation: anim_chat 0.2s ease-in-out;
}

.animation_chat3 {
  animation: anim_chat 0.2s ease-in-out;
}

.animation_chat4 {
  animation: anim_chat 0.2s ease-in-out;
}


.loaderStyle {
  background: linear-gradient(to right, #cbfdd9, #ccfed9, #c7f8db, #c1f0e0, #bdeae5, #b8e4e7, #b6e1e8, #b3ddeb, #afd8ee, #add5ef, #a3cdf3, #9cc3fc, #a0c9f5);

  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 10%;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scale(1.05);
  /* transform: translateX(30px); */
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.animation {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}
</style>
