<template>
  <div 
    class="fixed bottom-[8%] md:bottom-[14%] lg:bottom-[10%] left-2 w-[64%] sm:w-[35%] md:w-[35%] lg:w-[27%] xl:w-[25%]  2xl:w-[20%] md:bottom-[1rem] z-30 p-1">
    <div class="relative ">
      <svg @click="closecookiesModal"  xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="p-2 absolute mb-[1rem] top-0 right-0 bi bi-x bg-[#0891b2] hover:border-gray-400 hover:bg-gray-400 hover:text-white text-md text-white border-[#0891b2] border-2 rounded-tr-2xl" viewBox="0 0 12 12">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </div>
    <div class="bg-white p-2 md:flex md:flex-col md:gap-2 rounded-2xl overflow-hidden">
      <div class="flex gap-[1rem] mb-[1rem] mx-4 items-center flex-col lg:flex-row">
        <img src="../../assets/tigre_cookies.png" class="lg:w-30 h-auto xl:w-20 xl:h-20 w-12 rounded-full lg:rounded-none" alt="Cookies">
        <span class="xl:text-md text-md lg:text-xl  font-bold inline text-center lg:text-left">Cookies or not cookies ?</span>
      </div>
      <div class="lg:text-md xl:text-sm mx-2 mt-[-1rem] text-[11px] text-center lg:text-left">
        <p> <strong>Notre site vous intéresse… voici donc une information importante : </strong></p>
        <p class="mt-2 "><a href="https://meilleursforfaitsinternet.fr/" target="_blank" class="hover:underline">meilleursforfaitsinternet.fr</a> ne collecte pas de données personnelles via un dépôt de cookie. </p>
      </div>
      <div
        class="xl:w-full lg:w-full 2xl:w-full mx-auto mt-2 flex justify-center lg:justify-center lg:mr-[4rem] gap-5 lg:gap-12">
        <button @click="closecookiesModal" class="relative inline-flex items-center justify-center px-5 lg:py-4 py-2 overflow-hidden  font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
          <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#0891b2] rounded-full group-hover:w-56 group-hover:h-56"></span>
          <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
          <span class="relative text-[11px] md:text-[0.85rem] tracking-wider">J'ai compris</span>
        </button>
        <!-- <button @click="closecookiesModal" class="bg-[#0891b2] hover:bg-gray-400 hover:text-white w-fit xl:text-sm text-md text-white px-4 py-2 my-2 rounded-xl font-semibold">J'accepte</button> -->

        <a href="/politiquegestioncookies" target="_blank" class="relative inline-flex items-center justify-center px-2 overflow-hidden  font-medium tracking-tighter text-white bg-gray-800 rounded-lg group">
          <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#0891b2] rounded-full group-hover:w-56 group-hover:h-56"></span>
          <span class="absolute inset-0 w-full h-full rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
          <span class="relative text-center text-[11px] md:text-[0.85rem] tracking-wider">En savoir plus</span>
        </a>
        <!-- <a href="/gestion-des-cookies" target="_blank" class="bg-gray-400 hover:bg-[#0891b2] hover:text-white font-semibold bg-opacity-80 w-fit xl:text-sm text-md text-white px-4 py-2 my-2 rounded-xl">En savoir plus</a> -->
      </div>
    </div>
  </div>
</template>

<script setup>
/***
 * Importations des ressources 
 */
import {defineEmits } from 'vue';


/***
 * Déclaration des variables, des props et  des émits
 */
const emit = defineEmits(['close']);


/***
 * Fonctions  locales
 */

 // Fermer le modal après le click de l'utilisateur
const closecookiesModal = () => {
    emit('close')
}

</script>

<style></style>