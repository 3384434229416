
<template>
    <div class="min-h-screen flex items-center justify-center">
      <div class="text-center">
        <h1 class="text-8xl font-bold text-gray-800">404</h1>
        <p class="text-xl font-medium text-gray-600 mt-4">Page non trouvée</p>
        <router-link to="/" class="text-blue-500 hover:underline mt-4 block">
          Aller à l'accueil
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
//   export default {
//     name: 'NotFound',
//   };
  </script>
