<template>

<div>
  
   <router-view></router-view>
</div>
   
</template>

<script setup>


</script>
<style>
  html {
    background: rgb(243 244 246);
    background: linear-gradient(to right, #cbfdd9, #ccfed9, #c7f8db, #c1f0e0, #bdeae5, #b8e4e7, #b6e1e8, #b3ddeb, #afd8ee,#add5ef, #a3cdf3, #9cc3fc, #a0c9f5);
    /* background-color: #b1b1b15b ; */
    /* background: linear-gradient(to right, #c5e4ce, rgb(6, 6, 12)); */

  height: 100%;   
}

/* body {
  height: 100%;
  margin: 0;
} */
</style>