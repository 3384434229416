<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="fixed bg-white bg-opacity-50 top-0 left-0 z-50 w-full h-full flex items-center justify-center">
        <div class="bg-white w-56  relative md:max-w-lg mx-auto p-2 shadow-lg text-sm rounded-lg text-center">
            <div class="flex justify-center flex-col items-center mx-auto">
                <!-- <img class="rounded-full w-16 h-16 sm:w-20 sm:h-20" src="../../assets/mascotte_final.jpg"
                    alt="mascotteTigre"> -->

                <div class="m-4 ">
                    <h1 class="text-xl text-center  font-bold mb-4 text-black">Toc toc toc ✊ </h1>
                    <p>Cliquez sur l'un des deux boutons pour être rappelé rapidement ! 🙂 </p>
                </div>

                <div class="flex justify-center">
                    <button @click="closeGoodByeModal()"
                        class="bg-[#0891b2] p-2 px-3 text-white text-[12px] md:text-[14px] w-fit h-fit md:px-4 rounded-md font-semibold disabled:cursor-not-allowed">OK</button>
                </div>
            </div>

        </div>
    </div>
</template>


<script setup>
/***
 * Importations des ressources 
 */
import { defineEmits } from 'vue';



/***
 * Déclaration des variables, des props et  des émits
 */
const emit = defineEmits(['close']);




/***
 * Fonctions  locales
 */

// Fermet le modal après le click de l'utilisateur
const closeGoodByeModal = () => {
    emit('close')
}

</script>

<style></style>