import { createApp } from 'vue'
import router  from './router'
import App from './App.vue'
import "./main.css";
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';


// import Echo from "laravel-echo"

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '790a087b0e78159ef09d',
//     wsHost: window.location.hostname,
//     cluster : "mt1",
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true,
// });

createApp(App).use(router).mount('#app')
const BASE_URL = "https://meilleursforfaitsinternet.fr";
// const BASE_URL = "https://lesmeilleursoffres.fr";
// const BACK_URL = "https://lesmeilleursoffres.fr";
// const BACK_URL = "http://127.0.0.1:8000";
const BACK_URL = "https://api.meilleursforfaitsinternet.fr";
// const BACK_URL = "https://meilleursforfaitsinternet.fr";

const config = {
    BASE_URL,
    BACK_URL
}

export default config; 
